import * as actionTypes from "./types";

const initialState = {
	queue: [],
	selectedEvent: null
};

/**
 *
 * @param {object} state
 * @param {Array<{id: number}>} state.queue
 * @param {object} action
 * @returns {object} New state
 */
export default function feedEventsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.ADD_FEED_EVENT:
			// We don't need to create random IDs for feedEvents,
			//we can just use the UTC time now and add 1 for each new feedEvent. This way we can sort them by time withough having to worry about collisions
			const sliceThreshold = 50;
			const viewThreshold = 15;

			// When the queue exceeds the sliceThreshold, cut it back to the 
			// the view threshold.
			let newQueue;
			if (state.queue.length > sliceThreshold) {
				// Use slice to return the last elements of the array, that are
				// within the view threshold
				newQueue = state.queue.slice(state.queue.length - viewThreshold);
			} else {
				newQueue = [...state.queue];
			}

			// Finally always add the element to the queue
			newQueue.push(action.feedEvent);

			return {
				...state,
				queue: newQueue,
			};
		case actionTypes.UPDATE_FEED_EVENT:
			return {
				...state,
				queue: state.queue.map((event) =>
					event.id === action.feedEvent.id
						? { ...event, ...action.feedEvent }
						: event
				),
			};
		case actionTypes.REMOVE_FEED_EVENT:
			return {
				...state,
				queue: state.queue.filter((event) => event.id !== action.id),
			};
		case actionTypes.REMOVE_TAIL:
			return {
				...state,
				queue: state.queue.slice(0, -15),
			};
		case actionTypes.EVENT_SELECTED:
			return {
				...state,
				selectedEvent: action.event
			};
		default:
			return state;
	}
}
