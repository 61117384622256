import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { redirectToLogin } from "../../redux/auth/actions";
import {
  checkPhoneAndAddRecipientMessageToConversation,
  checkPhoneAndTerminateConversation,
} from "../../redux/recipients/thunks";
import SocketHandlerComponent from "./SocketHandler.component";
import { delayedAdd } from "../../redux/recipients/incoming_thunks";

/**
 * Simple wrapper to connect socket handler to redux. For the moment
 * this just adds the logout method
 * @param {*} props 
 * @returns 
 */
const SocketHandler = (props) => (<SocketHandlerComponent {...props}/>)

const mapStateToProps = (state) => ({
  userid: state.auth.userid,
  twilioroom: state.campaign.twilioroom,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkPhoneAndAddRecipientMessageToConversation,
      checkPhoneAndTerminateConversation,
      redirectToLogin,
      delayedAddCallback: (phone, whatWasSaid, ID) => delayedAdd(phone, whatWasSaid, ID)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SocketHandler);
