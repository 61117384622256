// This file contains all of the delayed incoming msg redux 
// actions and thunks. It takes care of creating timeouts,
// adding to the delayed msg store, cancelling if needed, and
// finally adding new messages to the conversations!

// HOW TO TEST
// * Create a button for triggering {delayedAdd}
// * Fix the main bugs


import { addItemToConversation, updateActionableConversationCount } from "./actions";
import { getDelayedIncoming, getNumberList, getRecipient } from "./selectors";
import { ADD_DELAYED_INCOMING, CLEAR_DELAYED_INCOMING } from "./types";

const APPEND_INCOMING_DELAY_SEC = (process.env.REACT_APP_APPEND_INCOMING_DELAY_SEC || 5) * 1000;


/**
 * The store of the delayed incoming sms will like this
 * {
 *  [phone]: {
 *    ...blob 
 *  }
 * }
 */

/**
 * @typedef {object} Blob
 * @property {string} ID
 * @property {string} currentscriptid
 * @property {("recipient" | "sender" | "accepted" | "bot")} who
 * @property {string} what
 * @property {Date} date
 */

/**
 * @typedef {object} DelayedMsg
 * @property {number} timeoutID 
 * @property {Blob} blob
 */

/**
 * Action creator for ADD_DELAYED_INCOMING
 * @param {string} phone The recipient phone
 * @param {Blob} blob The conversation blob to delay
 * @param {number} timeoutID The timeout ID in case we need to cancel it
 * @returns The dispatched action
 */
function addDelayedIncomingAction(phone, blob, timeoutID) {
  return function (dispatch) {
    return dispatch({
      type: ADD_DELAYED_INCOMING,
      phone,
      delayedInfo: {
        blob,
        timeoutID
      }
    });
  }
}

function clearDelayedIncomingAction(phone) {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_DELAYED_INCOMING,
      phone
    });
  }
}


/**
 * Get the stored incoming and add it to the conversation
 * @param {*} phone 
 */
function getStoredIncomingAndAddToConversationThunk(phone) {
  return function (dispatch, getState) {
    const state = getState();
    const delayed = getDelayedIncoming(state, phone);

    // This should not happen, but we might as well check for it
    if (!delayed) {
      console.error("Timeout ran, but there was no delayed incoming SMS stored!", phone);
      return;
    }

    const recipientData = getRecipient(state, phone);
    dispatch(addItemToConversation(phone, delayed.blob, recipientData.currentscriptid, false));
    dispatch(clearDelayedIncomingAction(phone));
    dispatch(updateActionableConversationCount());
  }
}

/**
 * Returns the stored delayed incomins msg or null
 * @param {object} state The redux state
 * @param {string} phone The phone of the convo to look for
 * @returns {DelayedMsg | null}
 */
function getDelayedIncomingAndCancelTimeout(state, phone) {
  const delayed = getDelayedIncoming(state, phone);
  // If there's a delay in progress, cancel it
  if (!delayed) {
    return null;
  }
  // Now happens in the function
  clearTimeout(delayed.timeoutID);
  return delayed;
}

/**
 * Add an incoming msg with a delay if necessary
 * @param {string} phone A 10 digit phone 
 * @param {string} whatWasSaid The text of the sms
 * @param {string} ID The Bandwidth generated ID of the SMS 
 */
export function delayedAdd(phone, whatWasSaid, ID) {
  return function (dispatch, getState) {
    const state = getState();
    const phoneInList = phone in getNumberList(state);

    if (!phoneInList) {
      return;
    }

    // If there already exists a delayed incoming sms, then cancel the timeout
    // and insert both messages to the conversation
    const delayed = getDelayedIncomingAndCancelTimeout(state, phone);
    dispatch(clearDelayedIncomingAction(phone));
    const recipientData = getRecipient(state, phone);
    const blob = {
      who: "recipient",
      what: whatWasSaid,
      // NOTE: The current version of this
      currentscriptid: recipientData.currentscriptid,
      date: new Date()
    };

    if (delayed) {
      // Then add the delayed blob AND this one to the convo
      // TODO: For now we're just adding them separatly
      dispatch(addItemToConversation(phone, delayed.blob, recipientData.currentscriptid, false));
      dispatch(addItemToConversation(phone, blob, recipientData.currentscriptid, false));
      // dispatch(addBlobsToConvo(phone, delayed.blob, blob));
      dispatch(updateActionableConversationCount());
    } else if (recipientData.justsentscript === 1) {
      // Otherwise, if the last msg is OUTGOING create a new delayed 
      // msg and add it to the delayed reducer

      const timeoutID = setTimeout(() => dispatch(getStoredIncomingAndAddToConversationThunk(phone)), APPEND_INCOMING_DELAY_SEC);
      // console.log("Timeout setting", phone, timeoutID);
      dispatch(addDelayedIncomingAction(phone, blob, timeoutID));
      // In this case, we don't update the count because we're not yet
      // adding the msg.
    } else {
      // In all other cases, just add the blob to the conversation
      dispatch(addItemToConversation(phone, blob, recipientData.currentscriptid, false));
      dispatch(updateActionableConversationCount());
    }
  };
}