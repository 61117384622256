import React, { useEffect, useState } from "react";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faForward,
  faPaperPlane,
  faCheckSquare,
  faRobot,
  faCheck,
  faCircleExclamation,
  faEye,
  faEyeSlash,
  faRectangleList,
  faRectangleXmark,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { eventSelected } from '../../redux/feed/actions';
import SelectedConversationModal from '../modals/SelectedConversationModal';

const feedEventMap = [
  { icon: faPaperPlane, class: "outgoing-script-sms" }, // OUTGOING_SCRIPT_SMS
  { icon: faPaperPlane, class: "outgoing-script-mms" }, // OUTGOING_SCRIPT_MMS
  { icon: faPaperPlane, class: "outgoing-sms" }, // OUTGOING_SMS
  { icon: faCheckSquare, class: "accept-answer" }, // ACCEPT_ANSWER
  { icon: faRobot, class: "bot-response" }, // BOT_RESPONSE
  { icon: faPaperPlane, class: "incoming-sms", flip: true }, // INCOMING_SMS
  { icon: faForward, class: "skipped-convo" }, // SKIPPED_CONVO
  { icon: faForward, class: "switched-convo" }, // SWITCHED_CONVO
  { icon: faCircleExclamation, class: "terminated" }, // AUTO_TERMINATED
  { icon: faCheck, class: "accept-answer" }, // CLOSED
  { icon: faRectangleList, class: "accept-answer" }, // NEW_NUMBERS_LOADED
  { icon: faRectangleXmark, class: "terminated" }, // NO_NEW_NUMBERS
  { icon: faRectangleList, class: "accept-answer" }, // ONGOING_RECORDS_LOADED
  { icon: faRectangleList, class: "terminated" }, // PERMANENT_OPTOUT
  { icon: faX, class: "terminated" }, // TERMINATED
  { icon: faRectangleList, class: "skipped-convo", } // RELEASED_INACTIVE_NUMBERS
];

/**
 * @param {object} props
 * @param {Array<FeedEvent>} props.items
 * @param {object} props.eventSelected
 * @returns
 */
function TexterEventFeed({
	items = [],
	selectedConvo = null,
	isSandbox = false,
	activeNumbers = [],
	eventSelected,
}) {
	const [showFeed, setShowFeed] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);

	const handleEventClick = (item) => {
		eventSelected(item);
		setSelectedEvent(item);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		eventSelected(null);
		setShowModal(false);
		setSelectedEvent(null);
	};

	const availableConvo = (recipientId) =>
		recipientId in activeNumbers ? "is-clickable" : "is-not-clickable";

	return (
		<>
			<h1 className={`title is-5 m-2`}>
				Event Feed
				<span className="is-pulled-right">
					<button
						className="button is-rounded is-short"
						title={showFeed ? "Hide Feed" : "Show Feed"}
						onClick={() => setShowFeed(!showFeed)}
					>
						<FontAwesomeIcon icon={showFeed ? faEyeSlash : faEye} />
					</button>
				</span>
			</h1>
			<div className="column is-full p-0 m-0 is-flex fullheight">
				<div className="event-feed">
					<div
						className={`event-feed-${
							!showFeed ? "closed" : "open"
						}`}
					>
						{items
							.sort((a, b) => a.id - b.id)
							.map((item, index) => {
								let info = feedEventMap[item.feedEventType];

								return (
									<div
										key={index}
										className={`item ${availableConvo(
											item.recipientId
										)}`}
										onClick={() => handleEventClick(item)}
									>
										<span
											className={
												feedEventMap[item.feedEventType]
													.class
											}
										>
											<FontAwesomeIcon
												icon={info.icon}
												flip={
													info.flip
														? "horizontal"
														: null
												}
											/>
										</span>{" "}
										<b>{item.message}</b>
										{item.recipientId &&
											` ID: ${item.recipientId}`}
									</div>
								);
							})}
					</div>
				</div>
			</div>
			{isSandbox &&
				showModal &&
				selectedConvo &&
				selectedConvo.number && (
					<SelectedConversationModal
						open={showModal}
						onClose={handleCloseModal}
						conversation={selectedConvo}
					/>
				)}
		</>
	);
}

export default reduxConnectedComponent(TexterEventFeed, (state) => {
		const selectedEvent = state.eventFeed.selectedEvent;
		const numbers = state.recipients.numbers;

		let enrichedSelectedEvent = selectedEvent;
		if (selectedEvent && numbers && selectedEvent.recipientId in numbers) {
			enrichedSelectedEvent = {
				...selectedEvent,
				number: numbers[selectedEvent.recipientId]
			};
		}

		return {
			items: state.eventFeed.queue,
			selectedConvo: enrichedSelectedEvent,
			isSandbox: state.campaign.active,
			activeNumbers: state.recipients.numbers,
		}
	},
  { eventSelected }
);