import React, { Fragment, PureComponent } from "react";
import { MessageBubble } from "../../common/MessageBubble";
import { ScriptErrorEventPopupContainer } from "../ScriptErrorEvents";
import StatusBar from "../StatusBar.container";
import TexterEventFeed from "../TexterEventFeed/TexterEventFeed";

class ConversationHead extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static defaultProps = {
		conversation: [],
	};

	componentDidMount() {
		this.forceScrollToBottom();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedCampaignCurrentPhone !== this.props.selectedCampaignCurrentPhone) this.forceScrollToBottom();
	}

	forceScrollToBottom() {
		document.getElementById("transcript").lastChild.scrollIntoView({
			behavior: "smooth",
		});
	}

	render = () => {
		const { conversation, status, disabled, mediaList } = this.props;
		/*
      bulma `.columns` are divided into 12 columns. To "center" the conversation, we make it half of the available space (6 cols) and off set it by 1/4th (3 cols)
      The terminate button's container 
     */
		return (
			<div className="pane columns m-0">
				<div className="column is-2 is-flex p-0">
					<StatusBar />
				</div>
				<div
					id="transcript"
					className="conversation-transcript column is-8"
				>
					<div className="message meta-status">
						{conversation.length === 0 &&
							!disabled &&
							"Interaction Pending"}
						{conversation.length > 0 && "Interaction Started"}
						{disabled && <>
							<p>WAITING FOR RESPONSES</p>
						</>}
					</div>
					{conversation.map((r, i) => {
						let mmsBubble = null;
						if (
							mediaList &&
							r.s160MediaId &&
							mediaList[r.s160MediaId]
						) {
							mmsBubble = (
								<MessageBubble
									messageSource={"media"}
									mediaId={
										mediaList[r.s160MediaId].media_name
									}
									mediaURL={
										mediaList[r.s160MediaId].gcloud_url
									}
									sendingFailed={r.sendingFailed}
									key={`bubble-media-${i}`}
								/>
							);
						}

						return (
							<Fragment key={`msg-${i}`}>
								{mmsBubble}
								<MessageBubble
									messageSource={r.who}
									messageContent={r.what}
									answerValue={r.value}
									sendingFailed={r.sendingFailed}
									key={`bubble-${i}`}
									// only pass answerList if this is the last message in the conversation and the message is from the recipient
									answerList={
										i == conversation.length - 1
											? this.props.answerList
											: []
									}
									selectedAnswerId={
										this.props.selectedAnswerId
									}
									toggleRadio={this.props.toggleRadio}
								/>
							</Fragment>
						);
					})}

					{status === "closed" && (
						<div className="dcm-sender-conversation-status">
							Interaction Closed
						</div>
					)}
					{status === "terminated" && (
						<div className="dcm-sender-conversation-status">
							Interaction Terminated
						</div>
					)}
				</div>
				<div className="column is-2 p-0">
					<TexterEventFeed />
				</div>
				<ScriptErrorEventPopupContainer />
			</div>
		);
	};
}

export default ConversationHead;
